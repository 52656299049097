import { Appearance } from "@stripe/stripe-js";
// eslint-disable-next-line import/no-unresolved
import { StripeElementsOptions } from "@stripe/stripe-js/dist/stripe-js/elements-group";

import { CountryCode } from "../../../settings/countries";
import { getPaymentMethodsAvailables } from "../../../utils/locale-configuration-utils";
import { assertNever } from "../../../utils/types";

export const getStripePublishableKey = (countryCodeIso: CountryCode): string => {
  switch (countryCodeIso) {
    case CountryCode.ES:
      return `${process.env.GATSBY_STRIPE_ES_PUBLISHABLE_KEY}`;
    case CountryCode.FR:
      return `${process.env.GATSBY_STRIPE_FR_PUBLISHABLE_KEY}`;
    case CountryCode.DE:
      return `${process.env.GATSBY_STRIPE_DE_PUBLISHABLE_KEY}`;
    case CountryCode.IT:
      return `${process.env.GATSBY_STRIPE_IT_PUBLISHABLE_KEY}`;
    case CountryCode.PT:
      return `${process.env.GATSBY_STRIPE_PT_PUBLISHABLE_KEY}`;
    default:
      assertNever(countryCodeIso);

      return "";
  }
};

export const getDefaultStripeElementsOptions = (
  countryCodeIso: CountryCode
): StripeElementsOptions => {
  const whiteColor = "hsl(0, 0%, 100%)";
  const b200Color = "hsl(209, 100%, 96%)";
  const br500Color = "hsl(14, 8%, 45%)";
  const br700Color = "hsl(15, 15%, 21%)";
  const o500Color = "hsl(16, 98%, 52%)";
  const r600Color = "hsl(0, 67%, 52%)";

  const appearance: Appearance = {
    theme: "stripe",
    labels: "floating",
    variables: {
      colorPrimary: br700Color,
      colorBackground: b200Color,
      colorDanger: r600Color,
      borderRadius: "16px",
      fontFamily: "ESRebondGrotesque, Arial, sans-serif",
      gridRowSpacing: "16px",
      gridColumnSpacing: "16px",
      tabSpacing: "8px",
    },
    rules: {
      ".Tab, .Input": {
        boxShadow: "none",
        border: `2px solid ${b200Color}`,
      },
      ".Tab:focus, .Tab--selected, .Tab--selected:focus, .Input:focus": {
        boxShadow: "none",
        border: `2px solid ${o500Color}`,
      },
      ".TabLabel, .Label": {
        color: br500Color,
      },
      ".Input": {
        color: br700Color,
        padding: "0.5rem 0.75rem",
      },
      ".Input:focus": {
        backgroundColor: whiteColor,
      },
      ".Input--invalid": {
        boxShadow: "none",
        border: `2px solid ${r600Color}`,
      },
      ".Error": {
        fontSize: "12px",
      },
      ".Label--resting, .Input": {
        fontSize: "14px",
      },
    },
  };

  return {
    appearance,
    currency: "eur",
    mode: "setup",
    fonts: [
      {
        family: "ESRebondGrotesque",
        src: `url(${process.env.GATSBY_URL_BASE}/fonts/ESRebondGrotesque-Regular.woff)`,
        weight: "400",
      },
    ],
    paymentMethodTypes: getPaymentMethodsAvailables(countryCodeIso),
  };
};
